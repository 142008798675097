// 多点备案机构平台-登录 接口
import request from "@/utils/request.js";

/**
 * 验证码登录
 * @param params
 * @returns {AxiosPromise}
 */
export function loginByVerifyCode(params) {
  return request({
    url: "/serviceUserService/api/practiceOrgLogin/loginByVerifyCode",
    method: "post",
    data: params
  });
}

/**
 * 获取验证码
 * @param params
 * @returns {AxiosPromise}
 */
export function loginSendVerifyCode(params) {
  return request({
    url: "/serviceUserService/api/practiceOrgLogin/loginSendVerifyCode",
    method: "post",
    data: params
  });
}

/**
 * 机构退出
 * @param params
 * @returns {AxiosPromise}
 */
export function logout(params) {
  return request({
    url: "/serviceUserService/api/practiceOrgLogin/logout",
    method: "post",
    data: params
  });
}
// 方法列表
// loginByVerifyCode，loginSendVerifyCode，logout
