export default {
  name: "多点执业备案管理",
  permissionsKey: "",
  icon: "fa fa-quora",
  children: {
    courseCardList: {
      name: "多点执业备案列表",
      permissionsKey: "",
      path: "/record_manage/index"
    }
  }
};
