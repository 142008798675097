import Layout from "@/views/layout/App.vue";
export default {
  path: "/record_manage",
  component: Layout,
  name: "RecordManage",
  redirect: "/record_manage/index",
  meta: {
    title: "执业备案管理"
  },
  children: [
    {
      path: "index",
      name: "recordManageList",
      meta: {
        title: "执业备案列表"
      },
      component: resolve =>
        require(["@/views/recordManage/index/Index.vue"], resolve)
    }
  ]
};
