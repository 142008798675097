import { useApiUrl } from "@/config/apiUrl";
import CommonConfig from "@/config/common";
import SystemConfig from "@/config/system";

export default {
  ...CommonConfig,
  ...SystemConfig,
  apiUrl: useApiUrl,
  corporation: "北京健康护航科技有限公司版权所有",
  siteName: "多点执业备案后台",
  platformUUID: "FWPRMP"
};
