export default {
  version: 0.1,
  timeout: 60000,
  apiPrefix: "",
  requestRetry: 4,
  requestRetryDelay: 800,
  tokenKey: "PRACTIVE_ACCESS_TOKEN",
  userInfoKey: "PRACTIVE_USER_INFO",
  permissionsKey: "PRACTIVE_PERMISSION_ARRAY",
  pageSizesArr: [16, 32, 64]
};
